import React from "react";
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import classNames from "classnames";

import ResearchAndDevelopmentDocuments from "../../components/research-and-development/ResearchAndDevelopmentDocuments";
import LengthIndicator from "../../components/common/LengthIndicator";

import * as classes from "./cell-biochip.module.scss";
import image1Src from "./img/cell-biochip-1.external.svg";

export default function ResearchAndDevelopmentCellBiochipPage({data}) {
    return (
        <article>
            <Helmet>
                <title>Клеточные биочипы для диагностики гемобластозов</title>
            </Helmet>
            <h2>Клеточные биочипы для диагностики гемобластозов</h2>
            <p>Стадия: НИОКР</p>
            <ResearchAndDevelopmentDocuments>
                <ResearchAndDevelopmentDocuments.Document
                    title="инструкция"
                    url={data["instructionsFile"]["publicURL"]} />
            </ResearchAndDevelopmentDocuments>
            <p>Параллельное определение морфологии и иммунофенотипа клеток крови или костного мозга.</p>
            <h3>Область применения</h3>
            <p>Диагностика гемобластозов в учреждениях здравоохранения второго уровня, не оснащенных проточной цитометрией.</p>
            <h3>Клетки на биочипе сортируются по поверхностным дифференцировочным маркерам до морфологического исследования</h3>
            <figure>
                <img
                    src={image1Src}
                    width={342}
                    height={342}
                    alt="Схема биочипа" />
                <figcaption>Схема биочипа</figcaption>
            </figure>
            <figure className={classNames(classes.figureWithLengthIndicator, classes.figureWithZoomIndicator)}>
                <StaticImage
                    src="img/cell-biochip-2.jpg"
                    alt="1 см"
                    layout="constrained"
                    width={342} />
                <figcaption>
                    1 см
                    <LengthIndicator
                        className={classNames(classes.lengthIndicator, classes.lengthIndicator1)} />
                </figcaption>
            </figure>
            <figure className={classNames(classes.figureWithLengthIndicator, classes.figureWithZoomIndicator)}>
                <StaticImage
                    src="img/cell-biochip-3.jpg"
                    alt="1 мм"
                    layout="constrained"
                    width={342} />
                <figcaption>
                    1 мм
                    <LengthIndicator
                        className={classNames(classes.lengthIndicator, classes.lengthIndicator2)} />
                </figcaption>
            </figure>
            <figure className={classes.figureWithLengthIndicator}>
                <StaticImage
                    src="img/cell-biochip-4.jpg"
                    alt="10 мкм"
                    layout="constrained"
                    width={342} />
                <figcaption>
                    10 мкм
                    <LengthIndicator
                        className={classNames(classes.lengthIndicator, classes.lengthIndicator3)} />
                </figcaption>
            </figure>
        </article>
    );
}

export const pageQuery = graphql`
    query {
        instructionsFile: file(relativePath: {eq: "instruktsiia-kletochnyi-biochip.pdf"}) {
            publicURL
        }
    }  
`;
