import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import * as classes from "./LengthIndicator.module.scss";

export default function LengthIndicator({className, style}) {
    return (
        <span
            className={classNames(classes.container, className)}
            style={style} />
    );
}

LengthIndicator.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
};
